.pc_spp_view {
  .module-spp-detail {
    .sizes {
      ul li {
        width: 100%;
        a {
          width: 70%;
          height: 63px !important;
          padding: 10px 10px 10px 42px;
          line-height: 43px;
          background-position: -112px -139px !important;
          &.active {
            background-position: -112px 13px !important;
          }
        }
      }
    }
    .options {
      .order_arrival {
        overflow: hidden;
      }
      .spp_product_status {
        white-space: nowrap;
        .temp_out_of_stock_msg {
          a.btn-comingsoon {
            line-height: 20px !important;
            padding-top: 3px;
          }
          a.btn-email {
            border: 1px solid;
          }
        }
      }
    }
    .icons {
      .icon-heart-message-text {
        margin-left: 10px !important;
      }
    }
    .page-spp {
      .col2 {
        h1 {
          font-size: 30px;
          color: rgb(83, 174, 141);
          letter-spacing: -0.5mm;
        }
        .options {
          .btn-add-to-bag {
            margin: 15px 0;
          }
          .icons {
            p.icon-heart-message-text {
              color: #000;
              margin: 8px 0 0 10px;
              font-size: 13px;
            }
          }
        }
        .sbHolder {
          a.sbSelector {
            padding-right: 20px;
          }
        }
        .sizes {
          ul.quickshop-sizes {
            float: left;
            li {
              height: 46px;
              a {
                height: 40px !important;
                line-height: 20px;
                background-position: -112px -148px !important;
                &.active {
                  background-position: -112px 2px !important;
                }
              }
            }
          }
        }
        .skin-type {
          ul.quickshop-type {
            float: left;
            li {
              height: 46px;
              width: 100%;
              a {
                height: 40px !important;
                padding-left: 15px;
                width: 70%;
                line-height: 20px;
                .label {
                  display: inline;
                  padding-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  #sticky-bar {
    #sticky_bar_content {
      .sticky_content {
        .sticky_prod_info h4 {
          line-height: 15px;
          height: 40px;
          padding: 12px 0 0 45px;
        }
      }
      .sticky_prod_select {
        margin-right: 25px;
        display: inline-flex;
        .spp_product_status {
          clear: none;
        }
        .price {
          padding: 13px 0;
          font-size: 15px;
        }
        .btn-add-to-bag {
          margin: 3px 5px;
          font-size: 14px;
          padding: 0 8px;
        }
        .selected_sku {
          white-space: nowrap;
          width: 160px;
          overflow: hidden;
          .label {
            padding-left: 40px;
          }
        }
        .dropdown-menu {
          width: 375px;
          ul {
            li {
              height: 46px;
              width: 100%;
              a {
                height: 40px !important;
                width: 100%;
                line-height: 20px;
                padding: 10px 10px 10px 42px;
                background-position: -112px -148px !important;
                &.active {
                  background-position: -112px 2px !important;
                }
              }
            }
          }
          &.skin-type {
            ul.quickshop-type {
              float: none;
              li {
                a {
                  padding: 10px 10px 10px 15px;
                  .label {
                    display: inline;
                    padding-left: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .module-may-we-suggest {
    .grid ul.product-grid {
      li.box {
        .product {
          .product-image {
            width: 117px !important;
            height: 135px !important;
          }
        }
      }
    }
  }
}

.node-elc-mpp {
  .field-elc-mpp-content {
    .cl-mpp-product-layout-v1 {
      .mpp-filter {
        h1 {
          letter-spacing: -0.3mm;
          font-size: 34px;
        }
      }
    }
  }
}

.badge_109 {
  background: #5dba98 !important;
}
