@mixin sub_line_as_title {
  .sd-product-spp {
    .elc-right-column-section .elc-product-display-name-wrapper {
      display: none;
    }
    .elc-product-full .elc-product-sub-display-name {
      @include text-title--med--no-xl;
      text-align: #{$ldirection};
      margin: 0 0 8px;
      color: $color-cl-light-black;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .elc-product-brief-wrapper .elc-product-display-name,
  .elc-product-brief .elc-product-display-name-wrapper {
    display: none;
  }
  .elc-product-quick-view-wrapper .elc-product-quick-view-details-wrapper {
    .elc-product-display-name {
      display: none;
    }
    .elc-product-sub-display-name {
      @include text-title--med--no-xl;
      margin-bottom: 8px;
      padding-#{$rdirection}: 20px;
    }
  }
}
