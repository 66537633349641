@mixin visible_display {
  display: block !important;
  visibility: visible !important;
}

@mixin text_inherit {
  line-height: inherit !important;
}

@mixin button_section {
  .add-to-bag {
    float: left;
    font-size: 10px;
    margin: 0 5px 0 0;
    width: 48%;
    display: inline-block;
  }
  .btn-shopnow {
    float: left;
    font-size: 10px;
    line-height: 13px;
    visibility: visible;
    display: inline-block;
    width: 48%;
    margin: 0px;
  }
  .add-progress {
    height: 22px;
    float: left;
    margin: 0px 5px 0 0;
    width: 48%;
    text-align: center;
  }
  .spp_product_status {
    float: left;
    font-size: 10px;
    margin: 0 5px 0 0;
    width: 46%;
    a.button {
      width: 100%;
    }
    .inactive_stock_msg {
      line-height: 22px;
    }
    .sold_out_stock_msg {
      line-height: 22px;
    }
    .coming_soon_stock_msg {
      a.button {
        margin-top: 0px !important;
      }
    }
    .temp_out_of_stock_msg {
      a.button {
        margin-top: 0px !important;
      }
    }
  }
}
