#delivery-options-panel {
  #delivery-info-shipping-methods {
    padding: 0 1em;
    input {
      margin: 3px 0 0 0;
      vertical-align: baseline;
      float: left;
      padding: 0;
      height: auto;
      margin-right: 5px;
    }
    label {
      margin: 0 5px 5px 5px;
      float: left;
    }
  }
  #delivery-info {
    clear: left;
  }
}

#shipping-panel {
  .checkout-panel-click_and_collect {
    padding: 0 !important;
  }
  .click-and-collect-method-select,
  .click_and_collect-standard-shipping {
    label {
      display: block;
      margin-left: 5px;
      float: left;
    }
  }
  .click-and-collect-method-select {
    margin-top: 1em;
    float: left;
    width: 100%;
    .radio-button {
      float: left;
      width: 100%;
    }
  }
}
