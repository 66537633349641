.appt-book-section-content {
  .appt-book-location-selector {
    .virtual__appointment--btn {
      background-color: #777;
      height: auto;
      line-height: 1;
      padding: 12px 11px 14px;
      margin-top: 20px;
      margin-bottom: 15px;
      letter-spacing: -0.015em;
      font-size: 16px;
      min-width: 277px;
      text-transform: uppercase;
      color: $color-white;
      cursor: default;
      border: none;
      @include breakpoint($ab-small-down) {
        width: 100%;
        margin-left: 0;
        font-size: 15px;
        margin-top: 13px;
        letter-spacing: -0.02em;
        padding-top: 13px;
      }
      &.disabled {
        background-color: $color-btn-gray-disabled;
        &:hover {
          background-color: $color-btn-gray-disabled;
        }
      }
      &:hover {
        background-color: $color-light-purple;
      }
    }
  }
}

#zoom-link {
  word-break: break-all !important;
}

.my-appointments {
  .appointment-container {
    .appointment-details {
      .virtual-meeting-url {
        display: block;
        text-align: left;
        word-break: break-all;
        text-decoration: underline !important;
      }
    }
  }
}
