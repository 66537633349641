#sms_promotion_container + * {
  clear: both;
}

.label_error {
  color: $color-red;
}

body.loyalty_offer_signup {
  #colorbox.loyalty-popup-custom-alignment.horizontal-align--right {
    #{$ldirection}: 0 !important;
    @include swap_direction(margin, 0 auto);
    #cboxWrapper {
      #cboxContent {
        @include breakpoint($ab-small-down) {
          height: 530px !important;
          width: 92% !important;
        }
        .loyalty_popover__header {
          font-size: 20px;
        }
        .loyalty_popover__sub-header {
          font-size: 16px;
        }
        .loyalty_popover__promo {
          @include swap_direction(margin, 10px 0 20px);
        }
      }
    }
  }
}

.account-loyalty {
  .section-header {
    .smart-rewards-logo {
      margin-#{$ldirection}: 0;
    }
  }
  .section-content {
    .loyalty__panel__points__bg__slide-container {
      .loyalty__panel__points__bg__Bar-container {
        .loyalty__panel__points__bg__text {
          width: 100%;
        }
        .arrow-down {
          vertical-align: 20px;
        }
        @media #{$cr19-medium-up} {
          width: 80%;
          max-width: 250px;
        }
      }
    }
  }
}

#signin {
  #main {
    .signin_container {
      .form-item.loyalty {
        .loyalty_terms_condition {
          width: 90%;
          label {
            font-family: $base-font-family !important;
          }
        }
      }
      .sign-in {
        #signin-block {
          .inner-block {
            #return-user {
              width: 45%;
            }
          }
        }
      }
    }
  }
}

#registration {
  #main {
    .registration_content.outer-wrap {
      #registration-wrapper {
        h3.section-header {
          margin-top: 40px;
        }
      }
    }
  }
  .loyalty-cancel-overlay {
    @media #{$small-only} {
      position: fixed;
    }
    .loyalty_popover__buttons {
      .cancel_loyalty_submit {
        @media #{$small-only} {
          display: inline-block;
        }
      }
      .link {
        @media #{$small-only} {
          @include swap_direction(margin, 12px 0 0);
          height: 45px;
          width: 120px;
          @include swap_direction(padding, 4px 11px);
          font-size: 18px;
          line-height: 44px;
          text-align: center;
          border: 1px solid $color-shade-grey;
          display: inline-block;
        }
      }
    }
  }
}

#loyalty-status__content {
  #loyalty__page__status {
    #loyalty__panel__offers__sku {
      li.offer_not_met {
        .loyalty__panel__offers__sku__cta {
          a.not_met {
            background-color: $color-btn-gray;
          }
        }
        .loyalty__panel__offers__sku__product__image {
          opacity: 0.5;
        }
      }
    }
  }
}

.loyalty__account__title {
  .loyalty__panel__title {
    word-break: keep-all;
  }
}
/* Checkout page */
.offer-section {
  .offer-code-panel {
    .checkout__panel__title {
      @media #{$medium-up} {
        font-size: 22px;
        padding-bottom: 16px;
        cursor: pointer;
      }
    }
  }
}

.checkout__offer-code__wallet {
  visibility: hidden; //hide pre slick carousel
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
  @media #{$medium-up} {
    height: 430px;
    margin-bottom: 30px;
  }
  &.offer_applied {
    &.slick-slider {
      .checkout__offer-code__wallet-item {
        &.automatic {
          .checkout__offer-code__wallet-apply {
            display: inline-block;
            border: 1px solid $color-shade-grey;
            background: $color-white;
            color: $color-shade-grey;
          }
        }
        .checkout__offer-code__wallet-apply {
          display: none;
        }
      }
    }
    .checkout__offer-code__wallet-item {
      .checkout__offer-code__wallet-apply {
        background: $color-light-gray;
        border: 1px solid $color-light-gray;
        color: $color-white;
      }
    }
  }
  .slick-dots {
    display: none !important;
    @media #{$medium-up} {
      display: block;
      top: 200px;
      bottom: auto;
    }
  }
  .slick-prev {
    background-color: rgba(0, 0, 0, 0);
    background-image: url('/media/export/cms/nav-arrows.png');
    background-repeat: no-repeat;
    height: 55px;
    @include swap_direction(margin, -27px 0 0);
    opacity: 0.3;
    position: absolute;
    text-indent: -9999px;
    top: 35%;
    transition: none;
    width: 27px;
    background-position: #{$ldirection} 10px;
    #{$ldirection}: 10px;
    z-index: 100;
    @media #{$medium-up} {
      #{$ldirection}: 5px;
    }
  }
  .slick-next {
    background-color: rgba(0, 0, 0, 0);
    background-image: url('/media/export/cms/nav-arrows.png');
    background-repeat: no-repeat;
    height: 55px;
    @include swap_direction(margin, -27px 0 0);
    opacity: 0.3;
    position: absolute;
    text-indent: -9999px;
    top: 35%;
    transition: none;
    width: 27px;
    background-position: #{$rdirection} 10px;
    #{$rdirection}: 10px;
    text-align: #{$ldirection};
    z-index: 100;
    @media #{$medium-up} {
      #{$rdirection}: 5px;
    }
  }
  .checkout__offer-code__wallet {
    &-item {
      text-align: center;
      font-size: 21px;
      @media #{$medium-up} {
        font-size: 22px;
      }
      &__image-container {
        @include swap_direction(padding, 0);
        margin-bottom: 16px;
        @media #{$medium-up} {
          margin-bottom: 35px;
        }
      }
      &__inner {
        float: none;
        width: auto;
        @include swap_direction(padding, 0);
      }
      &__image {
        display: none;
        width: 35%;
        background: $color-white;
        @media #{$medium-up} {
          width: 250px;
          height: 250px;
          background: $color-white;
        }
        @include breakpoint($ab-small-down) {
          width: 60%;
        }
        &__title {
          color: $color-black;
          font-size: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 180px;
          line-height: 1.1;
          letter-spacing: 0.02em;
        }
      }
      &__image_clinique {
        display: inline-block !important;
      }
      &__header {
        font-size: 21px;
        @media #{$medium-up} {
          font-size: 22px;
        }
      }
      &__description {
        font-size: 15px;
        line-height: 1.6;
        @media #{$medium-up} {
          font-size: 16px;
          line-height: 2;
        }
      }
      &__button-container {
        position: relative;
        margin-top: 20px;
        text-align: center;
      }
      &.is-applied {
        .checkout__offer-code__wallet {
          &-apply {
            display: none;
          }
          &-remove {
            display: inline-block;
          }
          &-selected {
            display: inline-block;
          }
          &-item__promo-applied {
            display: block;
            margin-top: 20px;
            font-size: 12px;
          }
        }
      }
      &__button-loader {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url('/media/images/account/loyalty/ajax-loader.gif') no-repeat center center
          $color-white;
        z-index: 10;
        @media #{$medium-up} {
          background-color: $color-white;
        }
      }
      &__promo-applied {
        display: none;
      }
      &__link-container {
        margin-top: 20px;
      }
      &__link {
        font-size: 12px;
        font-weight: bold;
        line-height: 0.44;
        text-transform: uppercase;
        text-decoration: underline;
      }
      .loyalty__panel__offers__offer-code-wrap {
        @media #{$medium-up} {
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 0.15em;
          @include swap_direction(margin, 6px 0);
          .loyalty__panel__offers__offer-code {
            font-weight: bold;
          }
        }
      }
    }
    &-apply,
    &-remove {
      @include swap_direction(padding, 20px 40px);
      background: $color-black;
      color: $color-white;
      font-size: 15px;
      font-weight: bold;
    }
    &-remove {
      display: none;
    }
    &-selected {
      display: none;
      background: $color-shade-grey;
      color: $color-white;
      pointer-events: none;
    }
    &-apply {
      height: 33px;
      line-height: 2.8;
    }
  }
  &-count {
    @include swap_direction(padding-bottom, 15px);
    text-align: center;
  }
}

#viewcart {
  .wrapper {
    .column.right.checkout__sidebar {
      .loyalty_points_checkout_offers {
        text-align: center;
      }
    }
  }
}

#landing.is-loyalty-member {
  .account_landing_wrapper {
    .account.breadcrumb.panel {
      padding-bottom: 1030px;
    }
    .best-sellers-loyalty-wrapper {
      h2 {
        max-width: 600px;
        word-break: keep-all;
      }
      .prod-shelf-row {
        .btn-shopnow {
          font-size: 1em;
          @include swap_direction(padding, 4px 10px 3px);
          text-transform: capitalize;
          width: auto;
        }
      }
    }
  }
}

.loyalty_mrkt_ldng {
  #loyalty_market_sticky_nav {
    .loyality_sign_off {
      @include swap_direction(padding, 20px 0 0 8px);
    }
    .loyalty_join_now,
    .loyalty_signin {
      button.btn_sign_in {
        @media #{$small-only} {
          @include swap_direction(padding, 14px 0);
          width: 100%;
          text-align: center;
        }
      }
      a.btn_join {
        display: block;
        text-align: center;
        @include swap_direction(margin, 15px 0 0);
        font-size: 18px;
        background: $color-black;
        border: 0;
        @include swap_direction(padding, 14px 50px);
        color: $color-white;
        font-weight: bold;
        @media #{$small-only} {
          @include swap_direction(padding, 14px 0);
          border: 2px solid $color-black;
          width: 100%;
          text-align: center;
        }
      }
      @media #{$small-only} {
        @include swap_direction(padding, 0 0 0 10px);
        width: 48%;
      }
    }
  }
  &.not_loyalty_member {
    .loyality_sign_off {
      display: block !important;
    }
  }
  .hero-full-width__content-text {
    .title--large {
      p {
        font-weight: normal;
      }
    }
  }
  .title--large {
    p {
      font-weight: bold;
    }
  }
  .title--med {
    p {
      font-weight: bold;
      @media #{$medium-up} {
        font-size: 32px;
      }
    }
  }
  .loyalty-grid-formatter {
    &__tier-header {
      font-weight: 600;
      height: auto;
      .col2,
      .col3,
      .col4 {
        white-space: nowrap;
      }
    }
  }
}

#loyalty_join {
  .h_loyalty.confirmation-link {
    color: $color-black;
    font-size: 13px;
    font-weight: normal;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    padding: 0;
  }
}
