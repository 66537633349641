$small-range-device: (0, 360px);
$small-device: '#{$screen} and (max-width:#{upper-bound($small-range-device)})' !default;

.product-full__media {
  vertical-align: top;
  display: inline-block;
  @media #{$medium-up} {
    width: 48%;
    display: inline-block;
    float: left;
  }
  .product-full__image {
    display: inline-block;
    vertical-align: top;
    margin: 0 auto;
    position: relative;
    max-width: 402px;
    &-wrapper {
      display: inline-block;
      position: relative;
      vertical-align: top;
    }
    &--slide,
    &--slide img {
      width: 402px;
      height: 464px;
      @media #{$medium-up} {
        @media #{$portrait} {
          width: 290px;
          height: 334px;
        }
      }
      @media #{$small-device} {
        width: 360px;
      }
    }
    &--slide {
      @media #{$medium-up} {
        display: none;
      }
      &.active {
        @media #{$medium-up} {
          display: block;
        }
      }
    }
  }
  .product-full__alt-images-slider {
    display: none;
    margin: 60px 20px 0 0;
    width: 50px;
    @media #{$medium-up} {
      display: none;
    }
    &.active {
      @media #{$medium-up} {
        display: inline-block;
      }
    }
    .slick-slide {
      padding: 5px 0;
      border: none;
    }
    .slick-arrow {
      height: 6px;
      width: 11px;
      left: 5px;
      position: relative;
      z-index: 100;
      padding: 20px;
    }
    .slick-prev {
      top: -25px;
      background: url('/media/images/icons/spp_thumb_carousel_caret_up.png') no-repeat center;
      &:before {
        content: none;
      }
    }
    .slick-next {
      bottom: -25px;
      background: url('/media/images/icons/spp_thumb_carousel_caret_down.png') no-repeat center;
      &:before {
        content: none;
      }
    }
  }
  .slick-dots {
    position: relative;
    bottom: 0;
    li {
      width: 10px;
    }
  }
  .product-full__alt-images-slider-button {
    height: 6px;
    width: 11px;
    margin: 0 auto;
    cursor: pointer;
  }
  .product-full__alt-images {
    display: inline-block;
    vertical-align: top;
    position: relative;
    &-thumb {
      cursor: pointer;
      border-bottom: 3px solid $color-white;
      &.active {
        border-color: $color-black;
      }
      img {
        width: 50px;
        height: 58px;
      }
      .thumb-multi-smoosh {
        display: inline-block;
        float: left;
        img {
          width: 25px;
        }
      }
    }
  }
}

.sticky_prod_info {
  .sticky-add-to-bag__image {
    height: 100%;
  }
}
