.account-favorites-mylists {
  .lists {
    .list {
      .list-of-the-month {
        .no-products {
          h3 {
            display: none;
          }
        }
        h3 {
          display: none;
        }
      }
      .create-list {
        .no-lists {
          .no-products {
            a.button {
              display: none;
            }
          }
        }
      }
      a {
        pointer-events: none;
        cursor: default;
      }
      a.button,
      a.create-list {
        width: 60%;
      }
    }
    .list-of-the-month {
      img {
        width: 90%;
        margin-left: 10px;
      }
    }
  }
  ol {
    li {
      .number {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

.create-wishlist-form {
  input#form--alter_list--field--COLLECTION_NAME {
    width: 265px;
  }
}

.cms_style_p_class {
  font-size: 18px;
  margin-top: 15px;
}

.no-favorites {
  .no-favorites-text {
    a.button {
      display: none;
    }
  }
}

.fav_products_pc_h3 {
  color: $color-cl-green-2;
  margin-bottom: 15px;
  font-size: 28px;
}

.fav_products_pc_p {
  line-height: 1.4;
  font-size: 21px;
}
