#pr-review-display {
  .p-w-r {
    .pr-snippet-reco-to-friend {
      .pr-reco-to-friend-message {
        font-size: 15px;
      }
    }
    .pr-multiselect {
      .pr-caret-icon {
        top: 31px;
      }
    }
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          &:before {
            content: '絞り込み: ';
          }
        }
        .pr-rd-review-header-sorts {
          width: 50%;
        }
      }
      &.pr-rd-display-desktop {
        .pr-rd-sort-group {
          &:before {
            content: '並び替え: ';
          }
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'はい';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'いいえ';
              }
            }
          }
          .pr-rd-flag-review-container {
            width: 45%;
          }
        }
        h2.pr-rd-review-headline {
          color: $color-black !important;
        }
        .pr-rd-description {
          .pr-rd-right {
            .pr-rd-reviewer-details {
              .pr-rd-author-location,
              .pr-rd-author-nickname {
                .pr-rd-bold {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  #pr-review-display-51520 {
    .p-w-r {
      .pr-multiselect {
        .pr-multiselect-button {
          padding: 13px 27px 8px 8px;
        }
      }
    }
  }
}

#pr-write {
  .p-w-r {
    .pr-war {
      .pr-footer {
        .pr-subscript {
          line-height: 1.3;
        }
        .pr-accessible-btn {
          @include swap_direction(padding, 3% 2%);
        }
      }
    }
  }
}

.elc-reviews {
  .tabs-nav {
    li {
      border-right: 1px solid $color-light-grey !important;
      &:last-child {
        display: none;
      }
    }
  }
}

/* Font Impementation */
#pr-write,
#pr-review-display,
.spp_page_wrap,
.spp_reviews,
#pr-reviewsnippet {
  .p-w-r {
    a {
      @extend %font-medium;
    }
    .pr-review-snapshot-header-intro {
      .pr-headline {
        @extend %font-medium;
      }
    }
    /* For Review Thank You page */
    .thank-you-page {
      h2.headline {
        @extend %font-medium;
      }
    }
  }
}

.mpp-grid,
.mpp-product {
  .p-w-r {
    .pr-category-snippet__total {
      @extend %font-medium;
    }
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-read-and-write {
          @extend %font-medium;
        }
      }
    }
  }
}

.spp-reviews-wrapper {
  &.tabs {
    .elc-reviews {
      .tabs-nav {
        .tab-active {
          width: auto;
        }
      }
    }
  }
}
