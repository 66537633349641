/**
 * @file _shared_all.scss
 *
 * JP specific styles for pc and mobile
 */

body {
  &.section-customer-care-ec-terms,
  &.section-customer-care {
    .cs-page-section-formatter-v1 {
      h2 {
        font-size: 22px;
        @media #{$cr19-large-up} {
          font-size: 30px;
        }
      }
      p {
        font-size: 12px;
        line-height: 1.5;
      }
    }
  }
  &.section-customer-care-ec-terms {
    &.device-mobile {
      .cs-page-section-formatter-v1 {
        @media #{$small-only} {
          h3 {
            font-size: 1.3em;
          }
        }
      }
    }
  }
  &.section-trustmark {
    .trustmark {
      width: 320px;
      margin: 0 auto;
      @media #{$cr19-large-up} {
        width: 964px;
        margin: 50px auto 0;
      }
      &_p {
        float: right;
        width: 100%;
        @media #{$small-only} {
          max-width: 320px;
          position: relative;
          top: 30px;
          margin-bottom: 60px;
        }
        @media #{$cr19-large-up} {
          width: 700px;
        }
      }
      .clear {
        clear: both;
      }
      p {
        font-size: 12px;
        line-height: 18px;
      }
      .top {
        margin-top: 10px;
      }
      a {
        color: $color-green-html;
        text-decoration: underline;
      }
      .logo_img {
        @media #{$small-only} {
          width: 105.5px;
          margin: 0 auto 0;
          position: relative;
        }
        img {
          float: left;
          width: 103.5px;
          height: auto;
          position: relative;
          top: 0px;
          @media #{$cr19-large-up} {
            width: 207px;
            top: 60px;
          }
        }
      }
      .margin01 {
        margin: 10px 0;
        @media #{$cr19-large-up} {
          margin: 20px 0;
        }
      }
      @media #{$small-only} {
        .bold {
          font-weight: bold;
        }
        .trustmark_wrapper {
          width: 320px;
          margin: 90px auto 20px;
          img {
            width: 320px;
            height: auto;
          }
        }
        .top02 {
          margin-top: 20px;
        }
        .notification {
          font-size: 10px;
          line-height: 14px;
        }
      }
    }
  }
  &.section-customer-care-tokushou-hou {
    .cs-page-section-formatter-v1 {
      @media #{$cr19-large-up} {
        tbody {
          min-width: 960px;
        }
      }
      p,
      li,
      dt {
        font-size: 12px !important;
        line-height: 1.5;
        font-weight: normal;
        color: $color-darker-grey;
      }
      h2 {
        font-size: 30px;
      }
      .clear {
        clear: both;
      }
      .bold {
        font-weight: bold;
      }
      .title {
        color: $color-black;
      }
      .heading-4 {
        color: $color-black;
      }
      .first_legal {
        margin-top: 15px;
        position: relative;
        overflow: hidden;
        width: 350px;
      }
      ul.ec_legal_1 {
        float: left;
        li {
          list-style: decimal;
        }
      }
      ul.ec_legal_2 {
        float: right;
        li {
          list-style: none;
        }
      }
      dl {
        float: left;
        margin-right: 30px;
        position: relative;
      }
      dt {
        width: 320px;
        font-weight: normal;
        padding: 10px 0 0;
        color: $color-cl-light-black;
        @media #{$cr19-large-up} {
          width: 350px;
        }
        span {
          font-weight: bold;
        }
      }
      .bottom-border,
      .bottom-border-2,
      .bottom-border-3 {
        display: block;
        border-bottom: dotted 1px $color-grey;
        position: relative;
        overflow: hidden;
        padding-bottom: 40px;
      }
      .bottom-border-3 {
        padding-top: 10px;
      }
      @media #{$cr19-large-up} {
        .bottom-border-2 dt {
          min-width: 960px;
        }
      }
    }
  }
}
