/*
 * Changes for clinique id phase two Changes only for US & CA
 * Used this $cliniqueid_phase_two variable to restrict for US & CA
 */
@mixin clid_phasetwo {
  .mix-and-match--grid-view {
    .mix-and-match {
      &--item {
        &--text-wrapper {
          height: 125px;
        }
      }
      &--title {
        h3 {
          @include start-breakpoint($medium-min) {
            font-size: 30px;
            padding-#{$ldirection}: 0px;
          }
        }
      }
      &--slider--dots {
        @include start-breakpoint($medium-min) {
          @include swap_direction(padding, 10px 0 30px 0);
        }
      }
      &--step--slider {
        @include start-breakpoint($medium-min) {
          justify-content: unset;
        }
      }
      &--item--wrapper {
        &--base {
          @include start-breakpoint($medium-l) {
            @include swap_direction(padding, 12px);
            margin-#{$ldirection}: 20px;
          }
          @include start-breakpoint($medium-max) {
            @include swap_direction(padding, 30px 150px 0 80px);
            margin-#{$ldirection}: 0;
          }
        }
        &--option {
          @include start-breakpoint($medium-l) {
            @include swap_direction(padding, 12px);
            margin-#{$ldirection}: 20px;
          }
          @include start-breakpoint($medium-max) {
            @include swap_direction(padding, 30px 150px 0 80px);
            margin-#{$ldirection}: 0;
          }
          .mix-and-match--step--slider {
            @include start-breakpoint($medium-max) {
              margin-#{$ldirection}: 0;
            }
          }
        }
        &--final--phase--two {
          @include start-breakpoint($medium-l) {
            @include swap_direction(padding, 12px);
            margin-#{$ldirection}: 20px;
          }
          @include start-breakpoint($medium-max) {
            @include swap_direction(padding, 30px 150px 0 80px);
            margin-#{$ldirection}: 0;
          }
          .mix-and-match--item {
            &--final--title {
              display: none;
              @include start-breakpoint($medium-min) {
                display: block;
              }
            }
            &--image-final {
              @include start-breakpoint($medium-min) {
                display: block;
                margin: auto;
                width: auto;
                height: 80%;
              }
              @include start-breakpoint($xlarge-max) {
                height: 100%;
              }
            }
            &--container {
              max-width: unset;
              justify-content: normal;
              @include start-breakpoint($medium-l) {
                width: 100%;
              }
              .mix-and-match--item--radio {
                @include start-breakpoint($xlarge-max) {
                  width: 30%;
                }
              }
              .mix-and-match--item ~ .mix-and-match--item {
                margin-top: -300px;
                @include start-breakpoint($medium-min) {
                  margin-top: 0;
                }
              }
              .mix-and-match--item {
                @include start-breakpoint($xlarge-max) {
                  width: 30%;
                }
              }
              hr {
                border: none;
                margin: 10px 0;
              }
            }
            &--final {
              &--change {
                cursor: pointer;
                text-decoration: underline;
              }
            }
            &--final--base--title,
            &--final--option--title {
              h3 {
                color: $color-black;
                font-family: $helvetica-bold-font-family;
              }
            }
            &--final--option--title {
              margin-top: 15px;
              margin-bottom: 8px;
            }
            &--final--base--change,
            &--final--option--change {
              padding: 10px 0;
              @include start-breakpoint($medium-min) {
                padding: 0 0 25px 0;
              }
            }
            &--final--base--change {
              @include start-breakpoint($medium-min) {
                border-bottom: 1px solid $color-btn-gray;
              }
            }
            &--final--base--option,
            &--final--option--option {
              padding-bottom: 15px;
              font-size: 15px;
              text-transform: capitalize;
              margin-#{$rdirection}: 5px;
              cursor: pointer;
              display: inline-block;
              padding-#{$ldirection}: 23px;
              @include start-breakpoint($medium-min) {
                display: block;
                margin-#{$rdirection}: 0;
                padding-#{$ldirection}: 30px;
              }
              position: relative;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
              }
              .checkmark {
                position: absolute;
                top: 3px;
                #{$ldirection}: 0;
                height: 12px;
                width: 12px;
                border-radius: 50%;
              }
              .circleRadio {
                border: 1px $color-black solid;
                height: 25px;
                width: 25px;
                top: -3px;
                #{$ldirection}: -5px;
                background-color: $color-white;
              }
              input:checked ~ .afterCheckmark {
                display: block;
              }
              .afterCheckmark {
                top: 6px;
                #{$ldirection}: 6px;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                position: absolute;
              }
            }
          }
        }
      }
    }
    a.mix-and-match--cta {
      width: 130px;
    }
    a.mix-and-match--cta.btn-learn-more {
      float: #{$ldirection};
      width: 130px;
      margin-#{$rdirection}: 10px;
      background: $color-white;
      color: $color-black;
      border: 1px solid $color-black;
    }
  }
}
