$jp-small-min: '#{$screen} and (max-width: 380px)' !default;
$jp-small-max: '#{$screen} and (max-width: 480px)' !default;
$jp-mob-max: '#{$screen} and (max-width: 530px)' !default;
$jp-medium-min: '#{$screen} and (min-width: 768px)' !default;
$jp-medium-max: '#{$screen} and (max-width: 768px)' !default;
/* reusable modernizr classes */
html {
  &.no-js .nojs_hidden,
  &.js .js_hidden {
    display: none;
  }
}
/* things get border-box so we can add padding and margins to 100% width elements */
input,
textarea,
.pg_wrapper,
#checkout-header,
.overlay-container,
fieldset {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.pre {
  white-space: pre;
}

.row,
.product {
  zoom: 1;
  clear: both;
}

.row:before,
.product:before,
.row:after,
.product:after {
  display: table;
  line-height: 0;
  content: '';
}
/* universal panel styles */
.panel {
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  zoom: 1;
  .block.right {
    float: right;
  }
  .content {
    padding: 1em;
    header {
      padding: 0.2em 0;
    }
  }
  header {
    zoom: 1;
    border-bottom-width: 0;
    margin: 0;
    background-color: #ccc;
    font-weight: bold;
    h2,
    .item-count {
      font-size: 1.4em;
      float: left;
      padding: 0.3em 0.5em;
      font-weight: bold;
      border-width: 0;
      margin: 0;
      line-height: 1.2em;
    }
    .edit {
      float: right;
      margin: 2px 1em 0 0;
      text-transform: uppercase;
    }
    h2 .edit {
      float: none;
      margin: 0;
      text-transform: none;
    }
  }
  h3 {
    font-size: 1.2em;
    font-weight: bold;
    border-bottom-width: 0;
    clear: both;
  }
  .form-item {
    margin: 1em 0;
  }
  label {
    display: block;
  }
  .checkbox input {
    height: auto;
  }
  .checkbox label {
    display: inline;
  }
  p {
    margin-bottom: 1em;
  }
  .column {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    &.left,
    &.right {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
  &:before,
  header:before,
  &:after,
  header:after {
    display: table;
    line-height: 0;
    content: '';
  }
  &:after,
  header:after {
    clear: both;
  }
}

.loading {
  background: url(/media/export/cms/479-01%20Clinique_LoadingGif_R22.gif) no-repeat center center;
  * {
    opacity: 0.8;
    filter: alpha(opacity=80);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  }
}

.messages {
  margin: 1em;
  font-size: 1.2em;
  background-color: #ffffff;
  border-bottom: none;
  padding: 0px;
  .single-message {
    color: #ef6ea8;
    margin-bottom: 5px;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    padding: 0;
    font-size: 14px;
  }
  .single-message .close_link {
    float: right;
    cursor: pointer;
    display: block;
    margin-left: 1em;
  }
  .single-message.INFORMATION {
    background-color: #fbcc72;
    border-color: #cc9942;
  }
}
/* CHECKOUT HEADER */
#checkout-header {
  background-color: #ddd;
  padding: 1em;
  margin-bottom: 1em;
  position: relative;
  h1 {
    float: left;
  }
  h2 {
    font-size: 1.4em;
    font-weight: bold;
    padding: 0;
    margin: 0;
    border-width: 0;
    position: absolute;
    bottom: 0.6em;
    left: 120px;
  }
  .shopping-region {
    float: right;
  }
  ul.nav-links {
    list-style-type: none;
    margin: 48px 0 0 0;
    padding: 0;
    li {
      display: inline-block;
      margin: 0 1em;
    }
  }
  ul {
    &.nav-links {
      &.detached {
        position: fixed;
        top: 0;
        width: 100%;
        margin-top: 0;
        z-index: 999999;
      }
    }
  }
  li.call-us,
  li.live-chat {
    float: right;
  }
  li.email-us {
    float: right;
    clear: right;
    margin-right: 0;
  }
  li.checkout-text {
    font-weight: bold;
    font-size: 1.2em;
  }
  #mobile-cart-button {
    display: none;
  }
  #mobile-cart-button.inactive:before {
    content: '+ ';
  }
  #mobile-cart-button.active:before {
    content: '- ';
  }
  #viewcart-panel {
    background-color: white;
    margin-top: 1em;
    h2 {
      display: none;
    }
  }
}

#mobile-breadcrumb {
  display: none;
  list-style-type: none;
  margin: 0.5em 0;
  li {
    display: inline-block;
  }
}
/* SIGN IN PANEL */
.sign-in-panel {
  .forgot-pass {
    margin-top: 1em;
  }
  .column,
  #billing-address-display.column,
  #shipping-address-display.column {
    width: 50%;
  }
  input {
    width: 100%;
  }
  img.img_mail {
    float: left;
    margin-right: 0.3em;
  }
  header#new-account-h,
  header#return-account-h {
    background: none;
  }
  #new-or-returning-radios {
    margin-bottom: 1em;
    input,
    label {
      display: inline-block;
      width: auto;
      padding: 0;
    }
    input {
      margin-top: -0.3em;
    }
    fieldset {
      float: left;
      width: 50%;
    }
  }
}

.sign-in-panel.paypal.finished {
  display: none;
}
/* NEED HELP PANEL */
body#index #need-help-panel {
  display: none;
}

#gift-options {
  width: 100%;
}
/* LINKS PANEL */
#links-panel {
  .links_list .link {
    margin-bottom: 0.5em;
  }
  .links_list .link:last-child {
    margin-bottom: 0;
  }
}
/* OFFER CODE PANEL */
#offer-code-panel {
  .btn-primary {
    margin-left: 10px;
  }
  .offer-code {
    &__content {
      padding: 0;
    }
    &__label {
      #{$ldirection}: -5px;
      letter-spacing: 0;
    }
  }
}
/* REVIEW PANEL */
#review-panel {
  form {
    margin-top: 1em;
  }
  input[type='radio'],
  input[type='checkbox'] {
    vertical-align: baseline;
  }
  input[type='radio'] {
    margin: 0 0.5em 0 1em;
    height: auto;
  }
  input[type='radio']:first-child {
    margin-left: 0;
  }
  input[type='checbox'] {
    margin: 0 0.5em 0 0;
  }
  .form-item.block {
    display: block;
  }
  .payment-option {
    display: inline-block;
    .related-media {
      display: block;
      padding: 5px 15px;
    }
  }
  #checkout_payment_select .related-media {
    display: block;
    margin-top: 0.2em;
  }
  #checkout_payment_card_select label {
    float: left;
    margin-right: 1em;
  }
  #checkout_saved_payments h3 {
    margin-bottom: 1em;
  }
  #checkout_saved_payment {
    h3 {
      margin-bottom: 1em;
    }
    #default-payment-method {
      margin: 1em 0;
    }
    #default-payment-method input[type='checkbox'] {
      float: left;
    }
  }
  #complete-btn {
    text-align: center;
    margin-bottom: 2em;
  }
  .security-info {
    float: right;
    padding: 10px;
    text-align: center;
  }
  .msg-order-email {
    display: none;
  }
  div.submit {
    text-align: center;
  }
  p.place-order-text {
    text-align: center;
    padding-top: 5px;
  }
}

#security-code-panel {
  margin: -20px;
  header a {
    float: right;
    margin-top: 5px;
  }
  .content {
    width: 100%;
    max-width: 540px;
    overflow: hidden;
  }
  .content .left {
    float: left;
    width: 40.740741%;
  }
  .content .right {
    float: right;
    width: 59.259259%;
    text-align: right;
  }
  .center {
    text-align: center;
  }
}
/* PAYMENT PANEL */
#payment-panel {
  .payment-info {
    margin: 0.5em 0;
    .additional-info {
      float: right;
      width: 20%;
      .required-notice {
        font-style: italic;
      }
      .related-media {
        margin: 0.3em 0;
      }
      a.security-information {
        font-weight: bold;
      }
    }
  }
  .form-item {
    input[type='checkbox'],
    input[type='radio'] {
      float: left;
      margin-right: 0.5em;
    }
    label {
      margin-right: 1em;
    }
    label:last-child {
      margin-right: 0;
    }
    .related-media {
      float: left;
      display: inline-block;
      width: 100%;
      margin-top: 0.5em;
      img {
        margin-right: 0.125em;
      }
      img:last-child {
        margin-right: 0;
      }
    }
  }
  .form-item > span > label {
    float: left;
  }
  ul {
    width: 100%;
    li {
      display: inline;
      float: left;
      margin-right: 1em;
    }
    label {
      float: left;
      margin-top: 0.0625em;
    }
  }
  .form-item-group.expiration-date {
    float: left;
    margin-right: 0.5em;
    .form-item {
      float: left;
      margin-top: 0;
      margin-right: 0.5em;
    }
  }
  .credit-card-options {
    padding: 0 1.5em;
    select {
      width: 100%;
      font-size: 1em;
    }
  }
  .btn-primary {
    display: block;
    margin: 1.5em auto 0;
  }
  .saved-credit-card-info {
    padding: 0 1.5em;
    p {
      margin: 1em 0;
    }
    .expiration-date {
      display: block;
    }
  }
}

/******* VIEWCART *********/
/* ------------------------ */

/* VIEWCART SIDEBAR TABS */
.tab-bar {
  margin-left: 0.5em;
  li {
    margin-right: 0.5em;
    float: left;
    a {
      border-width: 0px;
      color: #000;
      background-color: #ccc;
      display: block;
      padding: 7px 15px !important;
      color: #000;
      text-decoration: none;
      font-weight: bold;
    }
    a.active {
      border-width: 0px;
      color: #fff;
      background-color: #333;
      font-weight: bold;
      padding: 7px 15px !important;
    }
  }
}

body#viewcart .tab-content {
  clear: both;
}

html.js body#viewcart .tab-pane {
  opacity: 0;
  height: 0;
  overflow: hidden;
  -webkit-transition: opacity 1s ease;
  -moz-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
  -ms-transition: opacity 1s ease;
  transition: opacity 1s ease;
  header {
    display: none;
  }
}

html.js body#viewcart .tab-pane.active {
  display: block;
  opacity: 1;
  height: auto;
}
/* VIEWCART PRODUCTS */
.product {
  position: relative;
  margin: 1em 0;
  border-bottom: 1px solid $color_celeste_approx;
  .thumb {
    width: 21%;
    padding: 0;
  }
  .desc {
    width: 45%;
  }
  .total {
    font-weight: bold;
    text-align: right;
  }
  .price-label,
  .qty-label {
    display: none;
  }
  .swatch {
    width: 16px;
    height: 16px;
    background-color: #000000;
  }
  .remove_btn {
    margin-top: 1em;
  }
  > .column {
    padding: 0.8em 0.6em;
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    &.thumb {
      padding-top: 0;
    }
  }
  &.shaded {
    .desc {
      width: 20%;
    }
    .color {
      width: 25%;
    }
  }
}

.cart-header > .column {
  padding: 0.8em 0.6em;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.product .qty,
.cart-header .qty {
  width: 12%;
}

.product .total {
  width: 11%;
  float: right;
}

.cart-header {
  .total {
    width: 11%;
    float: right;
  }
  .products {
    width: 66%;
  }
  div {
    float: left;
  }
}
/* VIEWCART PAGE VIEWCART PANEL */
#viewcart-panel {
  border-bottom-width: 0;
  margin-bottom: 0;
  display: block;
  .viewcart-buttons-panel {
    border-width: 0;
    margin: 0;
    .continue-shopping,
    .go-shopping {
      display: none;
    }
    a {
      display: inline-block;
    }
  }
  header h2 .edit {
    float: none;
    margin: 0;
    text-transform: none;
  }
  .content {
    display: block;
    padding-bottom: 0;
  }
}
/* CHECKOUT PAGE VIEWCART PANEL */
body#index #viewcart-panel {
  border-bottom-width: 0;
  margin-bottom: 0;
  display: block;
  .content {
    display: block;
    padding-bottom: 0;
  }
  .viewcart-buttons-panel {
    display: none;
  }
  .cart-header {
    display: none;
  }
  .price {
    display: none;
  }
  .product {
    .qty {
      min-width: 4em;
    }
    .qty-label {
      display: inline;
    }
    .total {
      text-align: right;
    }
    .thumb {
      width: 20%;
    }
    .desc {
      width: 35%;
    }
    .qty {
      width: 22%;
    }
    .total {
      width: 23%;
      float: right;
    }
    .qty {
      margin-top: 0;
    }
  }
  .product.shaded {
    .color {
      position: relative;
      bottom: 0em;
      left: -20px;
      width: 100%;
      .swatch {
        position: absolute;
        top: 0px;
        left: 0;
      }
      .shade {
        position: absolute;
        top: 0px;
        left: 20px;
      }
      .shade-label {
        display: none;
      }
    }
  }
}

html.js div.qty input.update_btn {
  display: none;
}
/* SAMPLES PANEL */
.samples-panel {
  h3 {
    margin-bottom: 1em;
  }
  ul.product-list {
    overflow: hidden;
    .product {
      border-bottom: 1px dotted #ccc;
    }
    .product:last-child {
      border: none;
    }
  }
  ul.product-list > li.deactivate {
    color: #808080;
  }
  ul.product-list > li h4 {
    margin-top: 1em;
  }
  ul.skus {
    list-style: none;
    margin: 1em 0 0 0;
  }
  .swatch,
  .samples-panel input.sample-select {
    float: left;
    margin-right: 0.3em;
  }
  .product-img {
    max-width: 108px;
    width: 100%;
    float: left;
    text-align: center;
    img {
      width: 100%;
    }
    a {
      clear: left;
    }
  }
  .add-button,
  .no-thanks {
    display: none;
  }
}

.samples-overlay {
  .samples-panel {
    .product-list {
      overflow: hidden;
      max-width: 770px;
    }
    .product-list > li {
      float: left;
    }
    .product-list > li:nth-child(4n + 1) {
      clear: left;
    }
    .add-button,
    .no-thanks {
      display: inline-block;
    }
    .prev-next {
      display: none;
    }
  }
  .content {
    height: auto !important;
  }
  footer {
    display: none;
    visibility: hidden;
  }
}

.overlay-container .samples-panel .view-larger {
  display: none;
}
/* FAVORITES PANEL & PAST PURCHASES PANEL */
#past-purchases-panel,
#favorites-panel {
  h3 {
    margin-bottom: 1em;
  }
  ul.product-list {
    overflow: hidden;
  }
  ul.product-list .product {
    border-spacing: 0.4em;
    height: 130px;
    border-bottom: 1px dotted #ccc;
    display: block;
  }
  ul.product-list .product:last-child {
    border: none;
  }
  ul.product-list > li.deactivate {
    color: #808080;
  }
  ul.product-list > li h4 {
    margin-top: 0.5em;
    font-size: 1em;
  }
  ul.skus {
    margin-top: 1em;
  }
  .product-img {
    float: left;
    text-align: center;
  }
  .product-img img {
    width: 74px !important;
    height: 85px !important;
  }
  .product-img a {
    clear: left;
  }
  .details {
    margin-left: 120px;
  }
  .add-button,
  .no-thanks {
    display: none;
  }
  .price {
    width: 100% !important;
  }
}

#past-purchases-panel .past-purchases-item.product {
  height: 80px;
}

#favorites-panel .favorite-item.product {
  height: 100px;
}

#favorites-panel .swatch,
#past-purchases-panel .swatch,
.samples-panel input.sample-select {
  float: left;
  margin-right: 0.3em;
}
/* ORDER SUMMARY PANEL */
#order-summary-panel {
  margin-bottom: 0;
  header {
    background-color: transparent;
    h2 {
      float: right;
    }
  }
  .label {
    float: left;
    clear: left;
  }
  .value {
    text-align: right;
    float: left;
  }
  .total {
    margin-top: 0.5em;
  }
}
/* VIEWCART BUTTONS PANEL */
.viewcart-buttons-panel {
  border-top-width: 0;
}
/* SHIPPING PANEL */
#shipping-panel {
  .select-address {
    input[type='radio'] {
      margin: 3px 0 0 0;
      vertical-align: baseline;
      float: left;
    }
    input[type='radio'] :first-child {
      margin-left: 0;
    }
    input[type='radio'] + label {
      width: 90%;
    }
  }
  .select-address input[type='radio'] #qas {
    margin-top: 1em;
  }
  .default-shipping label {
    display: inline;
  }
  .content > div {
    margin-bottom: 1em;
  }
  .giftwrap,
  .card-message {
    display: block;
  }
  .giftwrap {
    margin: 10px 0 20px;
  }
  .card-message textarea {
    height: 4em;
  }
  #continue-btn {
    text-align: center;
  }
  #shipping-address-display header,
  #gift-options-display header {
    background: none;
  }
  .pc_email_promo_container {
    width: 100%;
    input {
      float: left;
      margin-top: 3px;
    }
    .field_label {
      width: auto;
      display: block;
      margin-right: 0px;
    }
  }
  #checkout_shipping_panel {
    .edit-shipping-radio {
      label {
        display: block;
        float: left;
        width: 20%;
        margin-left: 5px;
      }
      input[type='radio'] {
        float: left;
      }
    }
    .billing-address {
      fieldset.fs {
        border: none;
      }
    }
    .edit-billing-radio {
      label {
        display: block;
        padding-left: 20px;
        width: 96% !important;
      }
    }
  }
  &.substate-manual {
    .manual-address {
      display: none;
    }
    #manual-address-fields {
      display: block !important;
    }
  }
  &.edit header h2 .edit {
    color: #fff;
    text-decoration: none;
    cursor: default;
  }
  .shipping-panel .panel .collapsed {
    display: none;
  }
}
/* override default hidden state of sub-panel */
html.no-js #shipping-panel .select-menu {
  margin-left: 0em;
}
/* REGISTRATION PANEL */
#registration-panel {
  .how-will-we-use {
    clear: right;
    float: right;
    width: 24em;
    border: 1px solid #000;
    padding: 1em;
  }
  .how-will-we-use h3 {
    margin: 0 0 1em;
  }
  .how-will-we-use p {
    margin: 0;
  }
  .button-wrapper {
    text-align: center;
  }
  form#checkout_registration_panel {
    section.registration-form-container {
      .password {
        label.label-class {
          display: none;
        }
        .password label,
        .text label {
          pointer-events: none;
        }
      }
      input[type='password'] + label {
        margin-bottom: 1em;
      }
      .security_question select {
        width: 50%;
      }
      span.label-content {
        display: none;
      }
    }
  }
  section.checkout-registration {
    input {
      width: 50%;
    }
  }
  .security_question {
    margin-bottom: 12px;
  }
}
/* CONFIRMATION PANEL */
#confirmation-page {
  #guarantee-panel,
  #links-panel {
    margin: 2em 0;
  }
  #confirmation-panel .actions {
    margin-left: 2em;
    margin-bottom: 2em;
  }
  #confirmation-panel .banner {
    padding: 0 0 20px 0;
    margin: 0;
  }
  #confirmation-panel .banner img {
    width: 100%;
  }
}

#confirmation-panel {
  h3,
  p {
    margin-bottom: 1em;
  }
}
/* NEED HELP PANEL */
#need-help-panel {
  h3 {
    margin-bottom: 1em;
  }
  h4 {
    font-weight: bold;
    span {
      &.text {
        font-weight: normal;
      }
    }
  }
  .phone,
  .chat,
  .email {
    padding: 5px 0;
  }
}
/* OUR GUARANTEE PANEL */
#guarantee-panel {
  margin-top: 1em;
}
/* RECOMMEDED PRODUCTS PANEL */
#recommended-products-panel .recommended-product-items .recommended-item {
  display: table;
  width: 33%;
  float: left;
  height: 170px;
  a {
    float: left;
  }
  .description {
    float: left;
    width: 105px;
  }
  .addtobag,
  .quickshop {
    clear: left;
  }
}
/* SHIPMETHOD PANEL */
#viewcart #shipmethod-panel {
  border-width: 1px;
  header {
    float: left;
    background: transparent;
    padding-bottom: 0;
  }
  div.content {
    padding-top: 0;
    float: left;
    clear: both;
  }
  div.content div.delivery-note {
    max-width: 50%;
  }
}

.js #viewcart #shipmethod-panel div.content input {
  display: block;
} /* Hide the button! */
/* EMAIL OPT-IN */
fieldset.email_promotions .form_element a {
  display: block;
}
/* SHARED PANEL STYLES */
.new-address.form-container {
  width: 35em;
}

.form-container {
  .form-item {
    margin: 0.5em 0.5em 0.5em 0;
    display: inline-block;
  }
  .title,
  .default-shipping {
    display: block;
  }
  .address-to-use {
    width: 100%;
    .address_to_use_option {
      display: inline-block;
      margin: 0 1em 0.5em 0;
    }
  }
}

.pc_hidden {
  display: none;
}

@media #{$jp-medium-max} {
  body {
    font-size: 13px;
    div.body-container {
      width: 100%;
      padding: 0;
    }
    &.active-panel-review #mobile-breadcrumb {
      display: block;
    }
    &.active-panel-shipping #mobile-breadcrumb li.shipping span,
    &.active-panel-registration #mobile-breadcrumb li.registration span,
    &.active-panel-review #mobile-breadcrumb li.review span {
      font-weight: bold;
    }
  }
  #checkout-header {
    ul.nav-links {
      margin-top: 42px;
      clear: left;
      background-color: #ccc;
      margin: 1em -1em -1em -1em;
      padding: 0.4em;
    }
    ul.nav-links:after {
      content: '';
      display: table;
      clear: both;
    }
    #mobile-cart-button {
      display: inline-block;
    }
    .call-us span {
      display: none;
    }
    h1 {
      float: none;
      img {
        max-width: 100%;
        height: 55px;
      }
    }
    .checkout-text {
      padding-top: 3px;
    }
  }
  body.active-panel-review #checkout-header li.cart-button {
    display: none;
  }
  #header {
    margin-bottom: 0;
  }
  .pg_wrapper {
    padding: 1em 0.5em;
  }
  .column {
    &.left,
    &.right {
      float: none;
      width: 100%;
      padding: 0;
    }
    &.left {
      clear: left;
    }
  }
  .panel {
    margin: 1em 0;
    border-width: 1px;
    header {
      background-color: transparent;
      padding: 0.4em 1em;
      .item-count {
        margin-left: 1em;
      }
      h2,
      .item-count {
        font-size: 1.1em;
        padding: 0;
      }
    }
    .sub-section {
      margin-left: 0;
    }
    .form-item {
      textarea,
      select {
        width: 100%;
      }
    }
  }
  .panel.finished {
    background-color: #ccc;
  }
  .panel > .column {
    width: 48%;
    float: left;
  }
  /* BREADCRUMB STYLES */
  section.collapsed,
  #order-summary-panel.signin,
  #shipmethod-panel.signin,
  #offer-code-panel.signin,
  .sign-in-panel.finished,
  #offer-code-panel.shipping {
    display: none;
  }
  body#index {
    #need-help-panel {
      display: block;
    }
    #checkout-header #viewcart-panel {
      clear: both;
    }
  }
  .viewcart-buttons-panel {
    border-width: 0;
  }
  #shipmethod-panel header {
    background-color: transparent;
  }
  #order-summary-panel {
    header {
      display: none;
    }
    .total {
      padding-top: 0.5em;
    }
  }
  #registration-panel .how-will-we-use {
    float: none;
    width: auto;
  }
  /* Panels hidden from small screens  -- should also be conditionally loaded via perl/js to save bandwidth */

  .device-mobile #recommended-products-panel,
  .device-mobile .right .tab-switcher {
    display: none;
  }
  #shipmethod-panel .content {
    padding-top: 0;
  }
  /* Checkout form outside of the shipping panel */

  #checkout_review {
    .form-item {
      margin: 1em 0;
    }
    .msg-order-email {
      display: block;
    }
  }
  #confirmation-page {
    #confirmation-panel {
      .actions {
        margin-left: 0;
      }
    }
  }
}

@media #{$jp-mob-max} {
  .device-mobile {
    #checkout-header {
      ul.nav-links {
        li.live-chat,
        .email-us {
          display: none;
        }
      }
    }
    #viewcart-panel {
      .cart-header {
        display: none;
      }
      .qty {
        text-transform: uppercase;
      }
      .viewcart-buttons-panel {
        .or,
        .paypal-checkout {
          display: inline-block;
        }
      }
      .replenishment__description,
      .replenishment {
        clear: both;
        padding: 10px 0;
      }
    }
    .product {
      border-bottom: 1px dashed #ccc;
      margin-bottom: 1em;
      padding-bottom: 1em;
      .qty {
        .qty-label,
        form {
          display: inline-block;
          float: left;
          margin-right: 15px;
        }
        select {
          display: inline-block;
          margin-top: -0.2em;
          font-size: 0.9em;
          padding: 0;
        }
      }
    }
    .product:last-child {
      border-bottom-width: 0;
    }
  }
  body#index.device-mobile {
    .product {
      .thumb {
        width: 25%;
        padding: 0;
      }
      .desc {
        width: 75%;
      }
      .price {
        width: 20%;
        display: block;
      }
      .qty {
        width: 35%;
      }
      .total {
        width: 20%;
      }
    }
    .product.shaded {
      .color {
        width: 100%;
      }
      .desc {
        width: 75%;
      }
    }
    #viewcart-panel {
      .qty {
        margin-top: 0;
      }
    }
  }
  .device-mobile {
    .viewcart-buttons-panel {
      .or,
      .paypal-checkout {
        display: none;
      }
    }
  }
  .device-mobile {
    #main.single {
      #viewcart-panel {
        header.viewcart-header {
          .item-count {
            text-align: left;
            margin: 0;
            padding: 10px 0 0;
            float: right;
            clear: both;
          }
        }
        .qty {
          width: 25%;
          padding-right: 0;
          margin-top: 5px;
          padding-top: 0 !important;
        }
        .total {
          width: 30%;
          float: left;
        }
      }
      .product {
        .thumb {
          width: 22%;
          float: left;
        }
        .desc {
          width: 66% !important;
          margin: 20px 15px;
          float: left;
          padding-right: 0;
          padding-left: 20px;
          .sub_line {
            clear: both;
          }
        }
        .price {
          width: 40%;
          padding-left: 15px;
          clear: both;
        }
      }
    }
  }
  body#viewcart.device-mobile {
    #main.single {
      .product.cart-item {
        min-height: 110px;
        clear: both;
        margin-top: 0px;
      }
      .product {
        .remove_link,
        .remove-sample {
          position: absolute;
          bottom: 5px;
          right: 0;
          text-transform: capitalize;
        }
        .color {
          position: relative;
          top: 10px;
          left: 0;
          float: left;
        }
        .discounted_price {
          border: none;
          color: #ff195e;
          margin-top: 0;
          padding-top: 0;
        }
        .old_price {
          display: block;
          color: #dbdbdb;
          clear: both;
          margin: 5px 0 0;
          text-decoration: line-through;
        }
      }
      #viewcart-panel {
        .viewcart-buttons-panel {
          border-width: 0;
          margin: 0;
          position: relative;
          top: 0px;
          right: 2px;
          left: 10px;
          .content .continue-buttons a.continue-checkout {
            width: 50% !important;
          }
        }
      }
    }
  }
  body#samples.device-mobile {
    #main {
      div.cat_items {
        ul {
          li.sample-box {
            div.product-img {
              width: 35%;
              img {
                width: 100%;
              }
            }
            div.sample-product-name,
            .short_desc,
            .size {
              width: 64%;
              float: right;
              margin-top: 10px;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
  .device-mobile {
    #offer-code-panel input {
      width: auto;
      height: 34px;
      margin: 0;
      line-height: 10px;
      padding: 0 15px;
    }
    .main .sign-in-panel {
      .content {
        padding: 0 !important;
      }
      #new-or-returning-radios {
        label {
          width: 76%;
          float: left;
          margin-top: -0.3em;
        }
        input {
          width: 20%;
          float: left;
          margin-top: -0.3em;
        }
        fieldset {
          width: 46%;
          border: none;
        }
        margin-top: 1em;
      }
    }
    #return-user h3 {
      margin: 5px 20px;
    }
    #new-account {
      h3 {
        margin: 5px 20px;
      }
      input.field {
        margin: 5px 0;
      }
    }
    .panel.finished {
      background-color: #fff;
    }
    .main fieldset.address.fs {
      .form_element.state_container select {
        width: 100%;
      }
      padding: 0;
    }
    #shipping-panel .select-address input[type='radio']:first-child {
      float: left;
      margin: 3px 0;
    }
    #promo-panel {
      border: none !important;
      margin: 0 !important;
      .content {
        padding: 0em !important;
      }
    }
  }
  .device-mobile fieldset.address.fs {
    .form_element {
      margin: 10px 14px 10px 0;
    }
    #profile-postal.form_element .address_lookup_submit {
      height: 35px;
      line-height: 35px;
      margin-top: 5px;
    }
  }
  #main.multi fieldset.fs #profile-postal.form_element .address_lookup_submit,
  #main.multi fieldset.fs #profile-postal.form_element input.field {
    height: 35px !important;
    line-height: 35px !important;
    margin-top: 5px !important;
  }
  #index.device-mobile fieldset.fs #profile-postal.form_element input.field,
  #main.multi fieldset.fs #profile-postal.form_element input.field {
    width: 48%;
    margin-top: 15px !important;
  }
  body#index {
    #checkout_complete .payment-container label,
    .payment_gmo .payment-container label {
      width: 92%;
    }
    #checkout_billing .payment-form select,
    #checkout_payment .payment-form select {
      width: 42.5% !important;
    }
  }
  .device-mobile fieldset.fs #profile-postal.form_element .lookup_submit_note,
  #main.multi fieldset.fs #profile-postal.form_element .lookup_submit_note {
    padding-top: 10px;
    width: auto;
  }
  .device-mobile {
    h3,
    h4,
    div.address_item_heading {
      clear: both;
    }
  }
  #shipping-panel #checkout_shipping_panel .edit-shipping-radio label {
    width: auto;
    margin-right: 20px;
  }
  .device-mobile fieldset.fs #profile-postal.form_element .fieldset-note,
  #main.multi fieldset.fs #profile-postal.form_element .fieldset-note {
    left: inherit !important;
  }
  .device-mobile fieldset.fs #profile-postal.form_element label,
  #main.multi fieldset.fs #profile-postal.form_element label {
    float: left;
    width: 45%;
    position: absolute;
    top: 55px;
  }
  .device-mobile .main fieldset.fs div.form_element input[type='text'] {
    margin: 8px 5px 4px 0;
  }
  .main fieldset.fs div.form_element {
    input[type='tel'],
    textarea,
    select {
      margin: 8px 5px 4px 0;
    }
  }
  #shipping-panel #checkout_shipping_panel .edit-billing-radio label {
    width: 92%;
  }
  #confirmation-panel {
    .confirmation-panel {
      &__actions {
        .confirmation-panel {
          &__print-buttons {
            a.btn {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media #{$jp-small-max} {
  .sign-in-panel > .column {
    float: none;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    border-right-width: 0;
    border-bottom: 1px solid #ccc;
    clear: both;
  }
}

@media #{$jp-small-min} {
  #checkout-header ul.nav-links {
    li.call-us {
      margin: 0;
      font-size: 8px;
      padding: 2px;
    }
    &.detached li.call-us {
      margin-right: 1.5em;
    }
  }
  #review-panel .payment-option .related-media {
    padding: 5px 5px;
  }
  #main.single {
    .product {
      .thumb {
        width: 20%;
        float: left;
      }
      .desc {
        width: 66%;
        margin: 15px;
        float: left;
      }
      .remove_btn {
        position: absolute;
        top: 0;
        right: 0;
      }
      &.shaded .color {
        width: 100%;
      }
      .price {
        width: 34%;
        padding-left: 15px;
      }
      .qty {
        width: 15%;
        padding-right: 0;
        margin-top: 5px;
      }
      .total {
        width: 25%;
      }
    }
  }
}

body#index {
  @media #{$jp-medium-max} {
    &.active-panel-registration {
      #links-panel {
        display: none;
      }
    }
    #viewcart-panel {
      &.mobile_hidden {
        display: none;
      }
    }
  }
  .pg_wrapper {
    overflow: visible;
  }
  .item-count {
    float: left;
  }
  .panel.edit {
    header,
    h2 {
      background-color: #ffffff;
    }
  }
  #viewcart-panel {
    border-bottom-width: 0;
    margin-bottom: 0;
    display: block;
    .viewcart-buttons-panel {
      display: none;
    }
    .content {
      display: block;
      padding-bottom: 0;
    }
  }
  .cart-header {
    display: none;
  }
  #order-summary-panel {
    header h2 {
      float: left;
    }
    .label {
      text-align: left;
    }
  }
  .product {
    &.shaded {
      .responsive-shade-shim {
        height: 2.4em;
        clear: both;
      }
      .color {
        position: absolute;
        bottom: 1em;
        left: 0;
        width: 100%;
        .swatch {
          position: absolute;
          top: 0px;
          left: 0;
        }
        .shade {
          position: absolute;
          top: 0px;
          left: 20px;
        }
      }
    }
    .price {
      width: 100%;
    }
    .price-label {
      display: inline-block;
    }
    .qty {
      min-width: 4em;
    }
    .qty-label {
      display: inline-block;
    }
    .total {
      text-align: right;
    }
    .thumb {
      width: 40%;
      img {
        width: 100%;
      }
    }
    .desc {
      width: 40%;
    }
    .qty {
      width: 20%;
      text-align: right;
      padding-right: 0;
    }
    .total {
      width: 20%;
      float: right;
    }
    .qty {
      margin-top: 0;
    }
  }
  #checkout_complete,
  .payment_gmo {
    clear: both;
    padding-top: 10px;
    .payment-container {
      input {
        float: left;
        margin-right: 5px;
      }
      label {
        width: 45%;
        @media #{$jp-medium-max} {
          width: 43%;
        }
        float: left;
      }
      &__option {
        &--rakuten {
          clear: both;
          ~ label {
            width: 95%;
            @media #{$jp-medium-max} {
              width: 93%;
            }
          }
        }
      }
    }
    #continue-btn {
      clear: both;
      width: 20%;
      margin: 0 auto;
      &.rakuten {
        width: 40%;
        input {
          width: 100%;
          background: no-repeat center/100% url('/media/export/cms/checkout/rpay_btn.png');
          height: 50px;
          border: none;
          color: transparent;
          padding: 0;
        }
      }
      .btn-primary {
        font-family: 'HelveticaNeueLTStd65Medium', Verdana, Arial, Sans-Serif;
        background-color: #5dba98;
        text-transform: uppercase;
        color: white;
        line-height: 1.5;
        font-size: 0.9em;
        text-align: center;
        letter-spacing: 0;
        border: 0;
        padding: 4px 10px 3px;
        display: inline-block;
        zoom: 1;
        white-space: nowrap;
      }
    }
  }
  #checkout_billing,
  #checkout_payment {
    .payment-container {
      select {
        width: 100%;
      }
    }
    .payment-form {
      input[type='text'] {
        width: 100%;
      }
      select {
        width: 46.5%;
      }
      input[type='checkbox'] {
        float: left;
        margin-right: 10px;
      }
    }
  }
  #payment-information {
    clear: both;
    display: block;
    padding-top: 10px;
  }
}

@media #{$jp-medium-min} {
  body {
    .samples-overlay {
      .add-button {
        margin: 0 0 20px 0;
        padding-top: 0px;
      }
      .product-list {
        > li {
          float: none;
        }
        .skus {
          position: inherit;
        }
        .details {
          float: left;
          width: 70%;
        }
      }
    }
    &#index {
      .cart-items {
        .product.shaded {
          .responsive-shade-shim {
            height: auto;
          }
        }
      }
    }
    .mm-menu {
      text-align: center;
      .first {
        width: 100px;
      }
    }
  }
}

.device-mobile {
  .product .mpp_product {
    .desc {
      width: 100%;
    }
  }
}

@media #{$jp-medium-min} {
  #index {
    #checkout_payment {
      .card-number,
      .cvv {
        input {
          width: 100%;
        }
      }
    }
  }
}

@media #{$jp-medium-max} {
  body#samples {
    .main {
      #top {
        #header {
          .block-nodeblock.block-nodeblock-155360 {
            display: block;
            visibility: initial;
          }
        }
      }
    }
  }
  body#viewcart,
  body#samples,
  body#index,
  body#confirm {
    .main_container .main {
      #top {
        position: static;
        #header {
          position: fixed;
          top: 0;
          background-color: #ffffff;
          z-index: 1;
          .btn_navigation,
          #node-10492,
          #node-10498,
          #node-10488 {
            display: none;
          }
          .block-nodeblock-10502.block-template-basic-responsive-v1 {
            width: 100%;
            border-bottom: 1px solid #dbdbdb;
          }
          .mobile-checkout-back {
            background: url(/media/export/cms/nav-arrows_old.png) left 14px;
            background-repeat: no-repeat;
            background-size: 47px;
            height: 52px;
            width: 26px;
            position: absolute;
            left: 16px;
            display: block;
          }
        }
      }
    }
    .pg_wrapper {
      padding-top: 50px;
    }
    #main.single {
      .viewcart-header {
        border-top: 0;
      }
      #bottom-viewcart-buttons {
        .continue-checkout-btns {
          a.continue-checkout.continue-sticky {
            bottom: 0;
            position: fixed;
            line-height: 45px;
            height: 50px;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 15px;
            z-index: 1;
          }
        }
      }
    }
    form#offer_code {
      margin-bottom: 5px;
    }
    #samples-panel,
    #continue-btn {
      .submit {
        .btn-primary.continue-sticky,
        #continue-sticky {
          bottom: 0;
          position: fixed;
          line-height: 45px;
          height: 50px;
          left: 0;
          width: 100%;
          text-align: center;
          font-size: 15px;
          z-index: 1;
        }
      }
      .btn-primary.continue-sticky,
      #continue-sticky {
        bottom: 0;
        position: fixed;
        line-height: 45px;
        height: 50px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 15px;
        z-index: 1;
      }
    }
    .continue-sticky,
    #continue-sticky {
      bottom: 0;
      position: fixed;
      line-height: 45px;
      height: 50px;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 15px;
      z-index: 1;
    }
    #offer-code-panel {
      &.panel {
        border-bottom: 1px solid #dbdbdb;
      }
      #offer-code-panel__title {
        @media only screen and (max-width: 420px) {
          background: url(/media/export/cms/nav-arrows-vertical.png) no-repeat right -24px transparent;
          background-size: 8%;
        }
        @media only screen and (max-width: 370px) {
          background: url(/media/export/cms/nav-arrows-vertical.png) no-repeat right -20px transparent;
          background-size: 8%;
        }
        @media only screen and (max-width: 340px) {
          background: url(/media/export/cms/nav-arrows-vertical.png) no-repeat right -16px transparent;
          background-size: 8%;
        }
        background: url(/media/export/cms/nav-arrows-vertical.png) no-repeat right -28px transparent;
        width: 100%;
        height: 20px;
        background-size: 7.5%;
        margin: 5px 0 5px 0;
        &.open {
          background: url(/media/export/cms/nav-arrows-vertical.png) no-repeat right -2px transparent;
          background-size: 8%;
          @media only screen and (max-width: 370px) {
            background: url(/media/export/cms/nav-arrows-vertical.png) no-repeat right 0px transparent;
            background-size: 8%;
          }
          @media only screen and (max-width: 340px) {
            background: url(/media/export/cms/nav-arrows-vertical.png) no-repeat right 2px transparent;
            background-size: 8%;
          }
        }
      }
    }
    .pc_hidden {
      display: block;
    }
    #footer,
    .nav-promo,
    .mobile_hidden {
      display: none;
    }
    .opc__footer {
      margin: 10px 0 55px 0;
    }
    #sign-in-panel {
      margin: 25px 0 15px 0;
    }
    #main.single {
      #order-summary-panel {
        .cod_fee {
          width: 100%;
          overflow: hidden;
          padding-top: 10px;
          text-align: left;
        }
      }
    }
    #order-summary-panel {
      .cod_fee {
        width: 100%;
      }
    }
    #shipping-panel {
      .messages {
        margin: 5px 1em;
      }
      .shipping-edit-content {
        padding: 1em 0.2em !important;
        @media only screen and (max-width: 370px) {
          #checkout_shipping_panel {
            .phone1_container,
            .address2_container {
              margin: 10px 12px 10px 0;
              width: 46%;
              label {
                font-size: 12px;
                @media only screen and (max-width: 330px) {
                  font-size: 11.8px;
                }
                margin-top: calc(-2.2em - 1px);
                &:before {
                  margin: 0 0.5em;
                }
              }
            }
          }
        }
      }
    }
    #checkout_shipping_panel {
      h3.checkout__subtitle {
        margin: 0;
      }
      .shipping-address {
        padding: 0;
      }
      .form-item {
        &.last-name,
        &.first-name,
        &.last-name-alt,
        &.first-name-alt {
          width: 45%;
          margin-right: 10px;
        }
        &.last-name,
        &.first-name {
          input {
            margin-top: 10px;
          }
        }
        &.card-message-to,
        &.card-message-from {
          width: 98%;
        }
      }
      .state_container {
        label {
          display: block;
        }
      }
      input[type='text'],
      select,
      .selectBox {
        width: 105%;
      }
      fieldset#address {
        margin: 0px 0 5px 0;
        .state_container,
        .city_container,
        .address1_container,
        .address2_container,
        .phone1_container,
        .phone2_container {
          width: 45%;
          display: inline-block;
          select,
          input {
            width: 105%;
            font-size: 13px;
          }
          label {
            font-size: 13px;
          }
        }
        #profile-postal {
          input,
          label {
            font-size: 13px;
          }
        }
        .city_container {
          margin-top: 34px;
          top: -4px;
        }
        .address2_container {
          label {
            font-size: 13px;
          }
        }
      }
    }
    #checkout_registration_panel {
      .password,
      .text {
        input {
          width: 100%;
        }
        label {
          pointer-events: none;
        }
      }
      .password {
        .label-class {
          span.label-content {
            display: none;
          }
        }
      }
      .text {
        margin-bottom: 20px;
      }
    }
    #checkout_signin_new_user,
    #checkout_signin {
      input[type='tel'] + label:before,
      input[type='text'] + label:before,
      input[type='email'] + label:before,
      input[type='password'] + label:before {
        content: attr(data-required) attr(placeholder);
      }
    }
    #checkout_payment {
      .form-item {
        &.cvv {
          margin-bottom: 25px;
        }
        input {
          width: 100%;
        }
      }
      .expires-month-wrapper {
        label {
          margin-top: 10px;
        }
      }
    }
  }
  .samples {
    .viewcart-buttons-panel {
      display: none;
    }
  }
}

.section-freshpressed {
  .product {
    clear: none;
  }
}
.birth-month-container {
  .select-birth-month {
    width: 100%;
    height: 3em;
    margin-left: 1px;
    @media #{$medium-up} {
      width: 25%;
      height: calc(3em - -2px);
      margin-left: 0;
    }
  }
  .checkout-birthday {
    &__descriptive-text {
      padding-top: 10px;
    }
  }
}
