@if $password_strength {
  .password-field {
    &__info {
      @media #{$medium-up} {
        .checkout-registration__password & {
          width: 50%;
          border: 1px solid $color-black;
          &:before {
            top: 36%;
            #{$rdirection}: 75%;
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
      }
    }
  }
  #registration {
    .form_element {
      &.profile-info__item {
        .profile-info__change_pwd_link {
          margin-top: 10px;
        }
      }
    }
    #foreground-node {
      &.password-update-overlay {
        height: 550px !important;
      }
    }
    #profile_preferences {
      ul.error_messages_display {
        display: block;
      }
    }
  }
  .profile-password-update {
    &__rules {
      li {
        color: $color-btn-grey;
        display: flex;
      }
    }
    &.registration-page__content {
      #foreground-node & {
        .profile-password-update__button {
          &.disabled {
            opacity: 0.1;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
