html {
  #header {
    .gnav-drop-down-v1 {
      .dd-content {
        width: 425px;
        .gnav-cart-v1 {
          .cart-confirm-wrapper {
            .cart-overlay-products-heading {
              .hcenter {
                width: 256px;
              }
            }
            .cart-product-name {
              width: 130px;
            }
            .shade_info {
              overflow: hidden;
              height: auto;
              margin-bottom: 10px;
              .shade_thumb {
                float: left;
                width: 20px;
                height: 20px;
                margin-right: 5px;
              }
              .shadename {
                float: left;
                width: 95px;
              }
            }
            .prod-details {
              width: 125px;
              .qty-price.right-copy {
                float: left;
                width: 125px;
                .price {
                  float: right;
                  width: 80px;
                }
                .qty {
                  float: left;
                  margin-right: 0px;
                }
              }
            }
            min-height: inherit;
          }
        }
      }
    }
    .gnav-search-v1 {
      #typeahead-wrapper {
        .see-results {
          .result a.link {
            span.search-count {
              padding-right: 10px;
            }
          }
        }
      }
    }
    @media #{$medium-only} {
      .branding {
        width: 33%;
      }
    }
    .branding {
      .site-logo-responsive-v1 {
        a.left_logo-link {
          float: left;
        }
        a.right_logo-link {
          float: right;
        }
        @media #{$medium-only} {
          a {
            padding: 0 8px;
          }
        }
      }
    }
    .top-right {
      .block:last-child {
        padding-top: 10px;
      }
      .block-template-offer-promo-v3 {
        .offer-promo-v1 {
          .headline {
            span {
              font-size: 12px;
            }
          }
        }
      }
      .block-template-gnav-search-v1 {
        .gnav-search-v1 {
          #search {
            line-height: 40px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .mm-menu {
    li {
      a {
        font-size: 12px !important;
        &.link-three-step-collection {
          display: block;
          line-height: 15px;
          text-align: center;
          padding: 7px 0;
        }
      }
      span.title {
        padding: 0 4px !important;
      }
      &.signin.menu-username-link {
        span.title {
          line-height: 16px;
          padding: 6px 10px !important;
          span.borderRight {
            display: block;
            padding-right: 5px;
            border-right: 1px solid black;
          }
        }
      }
      &.signin {
        span.title {
          img {
            display: none;
          }
        }
      }
    }
  }
  #navigation {
    .product-tout {
      .shades .shade {
        div {
          float: left;
          &.shade-name {
            width: 120px;
            white-space: normal;
          }
        }
      }
      .price,
      .sizes {
        clear: both;
      }
    }
    .menu-item-blocks {
      .menu-item-block {
        min-height: 300px;
        .gnav-my-account-v1 {
          .signin_container {
            margin-bottom: 40px;
            .logged-in {
              ul.product-col3 {
                max-height: 200px;
                li {
                  min-height: 215px;
                }
              }
            }
          }
        }
      }
    }
  }
  #content {
    .vertical-carousel-navigation-v1 {
      .product-content-carousel-v1 {
        background: none;
        padding: 0;
        .flexslider {
          padding-bottom: 0;
          ol.flex-control-paging {
            position: static;
            margin-bottom: 1%;
          }
          @media #{$medium-only} {
            .flex-viewport {
              max-height: 366px;
              ul.slides li {
                .inner {
                  .full-width-2-row-v1 {
                    .outer-wrap {
                      background-position: 10px 0 !important;
                      background-size: 665px 366px !important;
                      .top {
                        top: 60px !important;
                        width: 410px !important;
                        h3 {
                          font-size: 23px !important;
                          line-height: 90% !important;
                          span {
                            font-size: 23px !important;
                          }
                        }
                        .subheading {
                          font-size: 18px !important;
                          p.date_4 {
                            margin: 15px 20px !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .product-content-grid-v1 {
        padding-bottom: 0;
        .product-tout {
          .product-info {
            position: relative;
            height: 170px;
            a.button {
              position: absolute;
              bottom: 0px;
            }
          }
        }
      }
      @media #{$medium-only} {
        .product-content-grid-v1 {
          padding: 0;
          h3 {
            display: none;
          }
        }
      }
    }
    .ebgift-promotion-v1 {
      margin-top: 30px;
      .col1 {
        width: 50%;
        float: left;
        .ebgift-wrapper {
          border: 2px solid #ccc;
          margin: 40px 0 0;
          padding: 20px;
          position: relative;
          h2 {
            position: absolute;
            top: -16px;
            background-color: #fff;
          }
        }
      }
      .col2 {
        width: 50%;
        float: left;
      }
    }
  }
}

body#shipping {
  #main.multi {
    .add_address_options {
      padding-bottom: 15px;
      a {
        padding-left: 20px;
      }
    }
    ul.address_controls {
      width: 100%;
      li#choose-address-options {
        width: 100%;
        span {
          display: inline-block;
          width: 100%;
          .radio.ship-address-option {
            float: left;
            width: 50%;
            min-height: 180px;
            input {
              float: left;
              margin-top: 3px;
            }
            .address-option-container {
              .address-option-content {
                padding-left: 30px;
              }
              .option-controls {
                padding: 10px 0 10px 30px;
                width: 100%;
                a {
                  padding-right: 15px;
                }
              }
            }
          }
        }
      }
    }
    #address_form_container {
      float: left;
      margin: 5px 0px 0px 0px;
      fieldset.address.fs {
        div.form_element {
          width: 42% !important;
          input[type='text'],
          select {
            width: 100%;
          }
        }
        div.postal_code_container {
          height: auto !important;
        }
        #profile-postal {
          input {
            width: 43%;
          }
          label {
            float: none;
          }
          .lookup_submit_note {
            position: absolute;
            top: 19px;
            left: 518px;
            font-size: 10px;
            width: 126px;
          }
          .fieldset-note {
            left: 289px;
            width: 100%;
          }
        }
        .state_container {
          clear: left;
        }
        div.state_container,
        .city_container {
          margin: 5px 20px 10px 0;
        }
        .phone_label_container {
          span.phone_req {
            width: 100%;
          }
          span.phone_options {
            display: block;
            margin: 10px 0;
            input {
              width: auto;
              vertical-align: middle;
              margin-right: 10px;
            }
            .phone_radio {
              margin-right: 20px;
            }
          }
        }
      }
    }
    .privacy-form-container {
      width: 58%;
      display: inline-block;
      float: left;
    }
    .continue-checkout {
      clear: none;
    }
  }
}

div.store-locator-body {
  fieldset.locator {
    div.form_element {
      &.state_container {
        margin-right: 20px;
      }
      &.city_container {
        margin-right: 20px;
      }
    }
  }
}

body.section-esearch {
  #search-wrapper {
    #results-container {
      #col-1 {
        display: none;
      }
      #col-2 {
        padding-left: 0px;
        .results {
          .result {
            width: 200px;
          }
        }
      }
    }
  }
}

.springtrend_image_v1 {
  background-color: #f2f2f2;
  min-height: 300px;
  overflow: hidden;
  padding: 65px 0 40px 0;
  width: 100%;
  .outer-wrap {
    h3 {
      color: #121212;
      font-size: 36px;
      line-height: 1;
      margin: 0 0 30px 0;
    }
    .spring_inner_block {
      float: left;
      margin: 0 15px 20px 0;
    }
    @media #{$medium-only} {
      .spring_inner_block {
        float: left;
        margin: 0 1.5% 2% 1.5%;
        width: 30.333%;
        a img {
          width: 100%;
        }
      }
    }
  }
}

#footer {
  .footer-top,
  .footer-bottom {
    .footer-top-v1,
    .inner {
      max-width: 1040px;
    }
    .inner {
      padding: 0;
      .field-menu {
        ul.menu {
          li {
            padding: 3px 8px 5px 5px;
            float: left;
            letter-spacing: 0.2px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  #header {
    .outer-wrap {
      .top-right {
        .block.block-template-offer-promo-v3 {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 840px) {
  #header {
    .outer-wrap {
      .column.branding {
        width: 33%;
      }
      .top-right {
        .block.block-template-offer-promo-v3 {
          .offer-promo-v1 .headline span {
            letter-spacing: -1px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #header {
    .outer-wrap {
      hgroup.branding {
        .site-logo-responsive-v1 {
          a.right_logo-link {
            display: none;
          }
        }
      }
    }
  }
}

.write_review #BVSubmissionContainer {
  padding-bottom: 80px;
}

#navigation {
  .navigation-right {
    float: right;
    margin: 0 -5px 0 0;
    .block {
      float: right;
    }
  }
}

.outer-wrap {
  .eh-thewink-hp-redesign-menu-formatter {
    ul.eh-thewink-hp-redesign-menu {
      width: 950px;
    }
  }
}

.eh-social-share-wrapper {
  .social_share_links {
    p.social_icons.pinterest,
    p.social_icons.mail {
      display: none;
    }
  }
}

section {
  &.clinique-spinner &#game-win-box {
    div {
      &.pop-up-box-inner {
        height: 730px;
        .win-info {
          h2 {
            font-size: 30px;
          }
        }
      }
    }
  }
  &#play-again-popup {
    .play-again-popup-container {
      p {
        &.sub-header {
          font-size: 30px;
        }
      }
    }
  }
}

#formatter {
  div {
    &#game-unavailable {
      div {
        &.pop-up-box-inner {
          align-items: baseline;
        }
      }
    }
  }
}
