#diagnostics {
  .clinical-reality__recommended-products {
    &--container {
      .clinical-reality__results-intro {
        display: none;
      }
    }
  }
}

.ccr-flexslider {
  &--left-nav,
  &--right-nav {
    width: 9px;
    height: 32px;
  }
  &--status {
    @include swap_direction(margin, 14px 0 0);
  }
}

.clinical-reality {
  &__results-intro {
    &-button-options {
      .clinical-reality--button {
        @include start-breakpoint($large-min) {
          max-width: 280px;
        }
      }
    }
  }
  .mobile_hidden {
    @include start-breakpoint($medium-m) {
      max-height: 1100px;
    }
    @include start-breakpoint($medium-max) {
      max-height: inherit;
    }
  }
  .pc_hidden {
    @include start-breakpoint($small-m) {
      max-height: 500px;
    }
  }
}
