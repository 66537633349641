@media (min-device-width: 1024px) {
  .gnav-header-formatter__sections--pc {
    .header-gnav-section__label,
    .header-gnav-section__link {
      font-weight: bold;
    }
  }
  .gnav-header-formatter__hamburger--pc {
    .text-link--style-2 {
      font-weight: bold;
    }
  }
  .gnav-header-formatter__sections-panel {
    .header-gnav-section__label,
    .header-gnav-section__link {
      font-weight: bold;
    }
    .show-subpanel {
      .header-gnav-section__label {
        min-width: 235px;
      }
    }
  }
  .header-gnav-links__title,
  .header-gnav-section__panel-link--header {
    font-weight: bold;
  }
  .header-gnav-section__panel {
    .header-gnav-links {
      .header-gnav-link {
        display: initial;
      }
    }
  }
  .email-signup-block__header {
    .email-signup-block__headline {
      p {
        font-weight: bold;
      }
    }
  }
  .email-signup-block__form-content {
    .email-signup-block__form-cta,
    .email-signup-block__form-email {
      input {
        font-weight: bold;
      }
    }
  }
  .sitewide-footer-formatter__links {
    .footer-links__title {
      font-weight: bold;
    }
  }
  .header-gnav-cart {
    .cart-confirm-wrapper {
      .cart-overlay-products-heading,
      .left-copy,
      .subtotal {
        font-weight: bold;
      }
    }
  }
  .section-zdc {
    .main {
      padding-bottom: 0 !important;
      .zdc {
        &__bg--display {
          width: 100%;
        }
        &__offer--apply {
          .cta {
            &__button {
              margin-top: 12px;
              padding: 9px;
              width: 100%;
            }
          }
        }
        &__popup--content {
          padding-top: 70px;
        }
      }
    }
  }
}
