$ab-small-down: 767px;
$color-black: #000;
$color-white: #fff;
$color-light-purple: #9e6f9c;
$color-btn-gray-disabled: #777;

#main {
  #content {
    .appt-book-page-header-content {
      .inner {
        max-width: 590px;
        margin: 10px auto 0;
        @include breakpoint($ab-ipad-landscape) {
          padding-left: 15px;
        }
        h2 {
          color: $color-black;
          @include breakpoint($ab-ipad-landscape) {
            font-size: 32px;
            h2 {
              margin-left: 145px !important;
            }
          }
        }
        h4 {
          @include breakpoint($ab-ipad-landscape) {
            font-size: 16px !important;
            max-width: 330px !important;
            line-height: 20px !important;
            margin-left: 145px !important;
          }
          .button {
            display: none;
          }
        }
      }
      &__dots {
        @include breakpoint($ab-ipad-landscape) {
          display: none !important;
        }
        @include breakpoint($ab-ipad-portrait) {
          display: none !important;
        }
      }
      &__title {
        color: $color-black;
        h2 {
          color: $color-black;
          @include breakpoint($ab-ipad-portrait) {
            margin-left: 162px !important;
          }
        }
      }
      &__subhead {
        h4 {
          @include breakpoint($ab-ipad-portrait) {
            font-size: 16px !important;
            max-width: 342px !important;
            line-height: 20px !important;
            margin-left: 162px !important;
          }
        }
      }
    }
    .service-select {
      .services-container {
        .service {
          .selection-bar {
            .selection-selector.priced {
              height: 74px;
              padding: 26px 0;
            }
            .selection-data {
              span {
                display: inline-block !important;
              }
            }
          }
        }
      }
    }
    .appointments-container {
      .services {
        .service {
          .selection-bar {
            .selection-selector.priced {
              height: 76px;
              padding: 26px 0;
            }
            .selection-data {
              .selection-price {
                /*word-spacing: -3px;*/
                display: inline-block;
                width: 100%;
                &:empty {
                  display: none;
                }
              }
            }
          }
        }
      }
      .book-appt-container {
        input {
          &.appt-book-first-name {
            float: right;
          }
          &.appt-book-last-name {
            float: left;
          }
        }
      }
    }
    .confirm-container {
      .total-time {
        &.priced {
          width: 60% !important;
        }
      }
      .appt-book-book-submit {
        &.priced {
          width: 40% !important;
        }
      }
    }
  }
}

.appointments-page.account__section {
  .appt-book {
    .appt-book-page-header {
      &-content {
        &__title {
          padding-left: 180px;
          padding-top: 25px;
          @include breakpoint($ab-ipad-landscape) {
            padding: 0;
            max-width: 480px;
            h2 {
              font-size: 30px;
              margin-top: 30px;
              margin-left: 100px;
            }
            h4 {
              font-size: 18px;
              line-height: 22px;
              margin-left: 100px;
              margin-top: -12px;
            }
          }
          h4 {
            font-size: 22px;
          }
          @include breakpoint($ab-ipad-portrait) {
            h2,
            h4 {
              margin-left: 105px;
            }
          }
        }
      }
    }
  }
}

#appointments {
  .appointments-page {
    .appt-book-page-header-content__title {
      h2 {
        margin-left: 200px;
        margin-top: 10px;
        font-weight: bold;
        font-size: 56px;
      }
      h4 {
        font-weight: bold;
        margin-left: 200px;
        font-size: 20px;
      }
    }
  }
}
