.mpp-product-group-v1 {
  .mpp-product-v1 {
    .product {
      height: 590px;
      .caret {
        top: 573px;
      }
      .product-details {
        min-height: 510px;
        .product-info {
          padding-left: 0;
          h3 {
            margin: 0;
          }
          .product-name,
          .product-price {
            padding: 0 15%;
          }
          .product-name {
            h3 {
              line-height: 15px !important;
            }
          }
          .reviews {
            color: gray !important;
          }
          .selectskuholder {
            width: 90% !important;
            position: absolute;
            bottom: 80px;
            left: 5%;
            .sbHolder {
              width: 98% !important;
              a.sbSelector {
                padding-right: 20px;
              }
            }
            p {
              font-size: 11px;
              left: 5%;
              position: relative;
              margin-bottom: 3px;
            }
          }
        }
        .mpp-button-holder {
          @include button_section;
          position: absolute;
          bottom: 20px;
          width: 90%;
          left: 5%;
        }
      }
    }
  }
}

#quickshop {
  top: -6px;
}

.cl-mpp-product-layout-v1 {
  .mpp-grid {
    ul {
      &.view-small,
      &.view-large {
        li.mpp-box {
          height: 400px;
          @include button_section;
          .selectskuholder {
            width: 98%;
            left: 2.5%;
            position: absolute;
            bottom: 55px;
            .sbHolder {
              width: 95%;
              a.sbSelector {
                padding-right: 20px;
              }
            }
            p {
              font-size: 11px;
              padding-left: 0px;
              margin-bottom: 3px;
            }
          }
          .mpp-button-holder {
            position: absolute;
            bottom: 20px;
            width: 95%;
            left: 2.5%;
          }
          .reviews {
            color: gray !important;
          }
        }
      }
      &.view-large {
        li.mpp-box {
          height: 590px;
        }
      }
      &.view-small {
        li.mpp-box {
          .mpp-product-hover-view {
            width: 205px;
            min-height: 300px;
          }
        }
      }
    }
  }
  .mpp-wrapper .breadcrumbs {
    margin: 0 0 30px 0;
    padding: 15px 0 0 0;
    li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 1px 0 0;
      padding: 0 5px 0 0;
    }
  }
}
